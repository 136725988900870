<template>
  <div class="box100">
    <div class="role_cont">
      <p class="formTitle">基本信息</p>
      <el-form :model="roleFrom" :rules="roleFromRule" ref="comInfo">
        <div class="flexCB">
          <el-form-item label="角色名称" class="detailFormItem" prop="rolename">
            <el-input v-model="roleFrom.rolename"  placeholder="请输入角色名称"></el-input>
          </el-form-item>
          <el-form-item label="角色描述" class="detailFormItem" prop="roleposi">
            <el-input v-model="roleFrom.roleposi"  placeholder="请输入角色描述"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <p class="formTitle">角色权限</p>
      <div class="role_content">
        <div class="level1" v-for="(item, index) in relepers" :key="index">
          <el-checkbox v-model="item.power" class="span1" @change="changeCheckFun(item)"> {{ item.name }}</el-checkbox>
          <div v-if="item.level === true" class="levelT">
              <div v-for="(item1,index1) in item.children" :key="index1">
                <el-checkbox v-model="item1.power" class="span2" @change="changeCheckFun(item,item1)"> {{ item1.name }}</el-checkbox>
                <div class="level3">
                  <div v-for="(item2,index2) in item1.meta" :key="index2">
                    <el-checkbox v-model="item2.power" class="span3" :disabled="item2.disabled" @change="changeCheckFun(item,item1,item2)"> {{ item2.name }}</el-checkbox>
                  </div>
                </div>
              </div>
          </div>
          <div v-if="item.level === false" class="level2">
            <div v-for="(item1,index1) in item.meta" :key="index1">
              <el-checkbox v-model="item1.power" class="span3" :disabled="item1.disabled" @change="changeCheckFun(item,item1)"> {{ item1.name }}</el-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flexCE">
      <div class="settingCancel" @click="FromCancel">取消</div>
      <div class="settingConfirm" @click="saveRole">保存</div>
    </div>
  </div>
</template>

<script>
import { roleAdd, roleUpdate } from '@/api/role'

var Base64 = require('js-base64').Base64
export default {
  name: 'roleSetting',
  props: ['editRole'],
  data () {
    return {
      roleFrom: {
        rolename: '',
        roleposi: ''
      },
      roleFromRule: {
        rolename: [
          { required: true, trigger: 'blur', message: '角色名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        roleposi: [
          { required: true, trigger: 'blur', message: '角色描述不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      },
      relepers: []
    }
  },
  watch: {
    editRole (val) {
      this.editRole = val
    }
  },
  created () {
    this.relepers = [
      {
        name: '公告管理',
        path: 'management',
        component: 'management/index.vue',
        icon: 'icon-notice',
        level: false,
        power: false,
        meta: [
          { name: '新增', disabled: false, power: false },
          { name: '删除', disabled: false, power: false },
          { name: '编辑', disabled: false, power: false },
          { name: '排序', disabled: false, power: false }
        ]
      },
      {
        name: '轮播图管理',
        path: 'rotation',
        component: 'rotation/index.vue',
        icon: 'icon-rotation',
        level: false,
        power: false,
        meta: [
          { name: '新增', disabled: false, power: false },
          { name: '删除', disabled: false, power: false },
          { name: '编辑', disabled: false, power: false },
          { name: '排序', disabled: false, power: false }
        ]
      },
      {
        name: '客户管理',
        path: 'customer',
        component: 'customer',
        icon: 'icon-customer',
        level: true,
        power: false,
        meta: {},
        children: [
          {
            name: '客户列表',
            path: 'customerList',
            component: 'customer/customerList.vue',
            power: false,
            meta: [
              { name: '查看详情', disabled: false, power: false },
              { name: '上传资料', disabled: false, power: false },
              { name: '导出', disabled: false, power: false }
            ]
          },
          {
            name: '客户分群设置',
            path: 'customerGroup',
            component: 'customer/customerGroup.vue',
            power: false,
            meta: [
              { name: '新增', disabled: false, power: false },
              { name: '删除', disabled: false, power: false },
              { name: '编辑', disabled: false, power: false }
            ]
          }
        ]
      },
      {
        name: '员工管理',
        path: 'staff',
        component: 'staff/index.vue',
        icon: 'icon-staff',
        level: false,
        power: false,
        meta: [
          { name: '新增', disabled: false, power: false },
          { name: '删除', disabled: false, power: false },
          { name: '编辑', disabled: false, power: false },
          { name: '重置密码', disabled: false, power: false },
          { name: '停启用', disabled: false, power: false }
        ]
      },
      {
        name: '部门管理',
        path: 'department',
        component: 'department/index.vue',
        icon: 'icon-department',
        level: false,
        power: false,
        meta: [
          { name: '新增', disabled: false, power: false },
          { name: '删除', disabled: false, power: false },
          { name: '编辑', disabled: false, power: false },
          { name: '绑定员工', disabled: false, power: false },
          { name: '解绑员工', disabled: false, power: false }
        ]
      },
      {
        name: '角色管理',
        path: 'role',
        component: 'role/index.vue',
        icon: 'icon-role',
        level: false,
        power: false,
        meta: [
          { name: '新增', disabled: false, power: false },
          { name: '删除', disabled: false, power: false },
          { name: '编辑', disabled: false, power: false }
        ]
      },
      {
        name: '营销管理',
        path: 'marketing',
        component: 'marketing',
        icon: 'icon-marketing',
        level: true,
        power: false,
        children: [
          {
            name: '套餐卡列表',
            path: 'marketList',
            component: 'marketing/marketList.vue',
            power: false,
            meta: [
              { name: '新增', disabled: false, power: false },
              { name: '删除', disabled: false, power: false },
              { name: '编辑', disabled: false, power: false },
              { name: '上下架', disabled: false, power: false }
            ]
          },
          {
            name: '套餐卡分组',
            path: 'marketGroup',
            component: 'marketing/marketGroup.vue',
            power: false,
            meta: [
              { name: '新增', disabled: false, power: false },
              { name: '删除', disabled: false, power: false },
              { name: '编辑', disabled: false, power: false }
            ]
          },
          {
            name: '抵扣券',
            path: 'rebate',
            component: 'marketing/rebate.vue',
            power: false,
            meta: [
              { name: '新增', disabled: false, power: false },
              { name: '删除', disabled: false, power: false },
              { name: '编辑', disabled: false, power: false },
              { name: '上下架', disabled: false, power: false }
            ]
          },
          {
            name: '代金券',
            path: 'vouchers',
            component: 'marketing/vouchers.vue',
            power: false,
            meta: [
              { name: '新增', disabled: false, power: false },
              { name: '删除', disabled: false, power: false },
              { name: '编辑', disabled: false, power: false },
              { name: '上下架', disabled: false, power: false },
              { name: '下载', disabled: false, power: false }
            ]
          },
          {
            name: '充值设置',
            path: 'recharge',
            component: 'marketing/recharge.vue',
            power: false,
            meta: [
              { name: '新增', disabled: false, power: false },
              { name: '删除', disabled: false, power: false },
              { name: '编辑', disabled: false, power: false },
              { name: '排序', disabled: false, power: false }
            ]
          }
        ]
      },
      {
        name: '商品管理',
        path: 'commodity',
        component: 'commodity',
        icon: 'icon-commodity',
        level: true,
        power: false,
        children: [
          {
            name: '商品列表',
            path: 'commodityList',
            component: 'commodity/commodityList.vue',
            power: false,
            meta: [
              { name: '新增', disabled: false, power: false },
              { name: '删除', disabled: false, power: false },
              { name: '编辑', disabled: false, power: false },
              { name: '明细', disabled: false, power: false }
            ]
          },
          {
            name: '商品分类',
            path: 'commoditySort',
            component: 'commodity/commoditySort.vue',
            power: false,
            meta: [
              { name: '新增', disabled: false, power: false },
              { name: '删除', disabled: false, power: false },
              { name: '编辑', disabled: false, power: false }
            ]
          },
          {
            name: '商品品牌',
            path: 'commodityBrand',
            component: 'commodity/commodityBrand.vue',
            power: false,
            meta: [
              { name: '新增', disabled: false, power: false },
              { name: '删除', disabled: false, power: false },
              { name: '编辑', disabled: false, power: false }
            ]
          },
          {
            name: '商品单位',
            path: 'commodityUnit',
            component: 'commodity/commodityUnit.vue',
            power: false,
            meta: [
              { name: '新增', disabled: false, power: false },
              { name: '删除', disabled: false, power: false },
              { name: '编辑', disabled: false, power: false }
            ]
          }
        ]
      },
      {
        name: '服务管理',
        path: 'service',
        component: 'service',
        icon: 'icon-service',
        level: true,
        power: false,
        children: [
          {
            name: '服务列表',
            path: 'serveList',
            component: 'service/serveList.vue',
            power: false,
            meta: [
              { name: '新增', disabled: false, power: false },
              { name: '删除', disabled: false, power: false },
              { name: '编辑', disabled: false, power: false },
              { name: '上下架', disabled: false, power: false }
            ]
          },
          {
            name: '服务标签',
            path: 'serveLabel',
            component: 'service/serveLabel.vue',
            power: false,
            meta: [
              { name: '新增', disabled: false, power: false },
              { name: '删除', disabled: false, power: false },
              { name: '编辑', disabled: false, power: false }
            ]
          },
          {
            name: '服务分组',
            path: 'serveGroup',
            component: 'service/serveGroup.vue',
            power: false,
            meta: [
              { name: '新增', disabled: false, power: false },
              { name: '删除', disabled: false, power: false },
              { name: '编辑', disabled: false, power: false }
            ]
          }
        ]
      },
      {
        name: '财务管理',
        path: 'finance',
        component: 'finance/index.vue',
        icon: 'icon-finance',
        level: false,
        power: false,
        meta: [
          { name: '导出', disabled: false, power: false }
        ]
      },
      {
        name: '门店管理',
        path: 'store',
        component: 'store',
        icon: 'icon-store',
        level: true,
        power: false,
        children: [
          {
            name: '店铺列表',
            path: 'storeList',
            component: 'store/storeList.vue',
            power: false,
            meta: [
              { name: '新增', disabled: false, power: false },
              { name: '删除', disabled: false, power: false },
              { name: '编辑', disabled: false, power: false },
              { name: '上下架', disabled: false, power: false }
            ]
          },
          {
            name: '美容师列表',
            path: 'storeCosmet',
            component: 'store/storeCosmet.vue',
            power: false,
            meta: [
              { name: '删除', disabled: false, power: false },
              { name: '编辑', disabled: false, power: false },
              { name: '上下架', disabled: false, power: false }
            ]
          },
          {
            name: '美容师业绩',
            path: 'storeKpi',
            component: 'store/storeKpi.vue',
            power: false,
            meta: [
              { name: '导出', disabled: false, power: false }
            ]
          }
        ]
      },
      {
        name: '售后管理',
        path: 'after',
        component: 'after',
        icon: 'icon-after',
        level: true,
        power: false,
        children: [
          {
            name: '评价管理',
            path: 'afterAssess',
            component: 'after/afterAssess.vue',
            power: false,
            meta: [
              { name: '删除', disabled: false, power: false },
              { name: '回复', disabled: false, power: false }
            ]
          },
          {
            name: '退款管理',
            path: 'afterRefund',
            component: 'after/afterRefund.vue',
            power: false,
            meta: [
              { name: '查看详情', disabled: false, power: false }
            ]
          }
        ]
      },
      {
        name: '订单管理',
        path: 'order',
        component: 'order/index.vue',
        icon: 'icon-order',
        level: false,
        power: false,
        meta: [
          { name: '查看详情', disabled: false, power: false }
        ]
      },
      {
        name: '前台管理',
        path: 'reception',
        component: 'reception',
        icon: 'icon-reception',
        level: true,
        power: false,
        children: [
          {
            name: '开单',
            path: 'receptionBill',
            component: 'reception/receptionBill.vue',
            power: false,
            meta: [
              { name: '新增', disabled: false, power: false },
              { name: '删除', disabled: false, power: false },
              { name: '分配美容师', disabled: false, power: false },
              { name: '已完成订单', disabled: false, power: false }
            ]
          },
          {
            name: '充值',
            path: 'receptionCashier',
            component: 'reception/receptionCashier.vue',
            power: false,
            meta: [
              { name: '刷新', disabled: false, power: false },
              { name: '分配美容师', disabled: false, power: false }
            ]
          },
          {
            name: '套餐卡',
            path: 'receptionCard',
            component: 'reception/receptionCard.vue',
            power: false,
            meta: [
              { name: '刷新', disabled: false, power: false },
              { name: '分配美容师', disabled: false, power: false }
            ]
          },
          {
            name: '预约管理',
            path: 'receptionOrder',
            component: 'reception/receptionOrder.vue',
            power: false,
            meta: [
              { name: '详情', disabled: false, power: false },
              { name: '确认', disabled: false, power: false },
              { name: '分配美容师', disabled: false, power: false },
              { name: '已完成订单', disabled: false, power: false }
            ]
          }
        ]
      }
    ]

    if (this.editRole !== null) {
      this.roleFrom = this.editRole
      this.relepers = JSON.parse(Base64.decode(this.editRole.relepers))
    }
  },
  methods: {
    changeCheckFun (item, item1, item2) {
      if (item && item1 === undefined && item2 === undefined) {
        if (!item.power) {
          this.setHidden(item, false)
        } else {
          this.setHidden(item, true)
        }
      }
      if (item1 && item2 === undefined) {
        if (item1.power) {
          item.power = true
          this.setHidden(item1, true)
        } else {
          this.setHidden(item1, false)
        }
      }
      if (item2) {
        if (item2.power) {
          item.power = true
          item1.power = true
          this.setHidden(item2, true)
        } else {
          this.setHidden(item2, false)
        }
      }
    },
    setHidden (item, booem) {
      if (item.children) {
        item.children.forEach(itm1 => {
          itm1.power = booem
          itm1.meta.forEach(itm2 => {
            itm2.power = booem
          })
        })
      } else {
        if (item.meta) {
          item.meta.forEach(itm2 => {
            itm2.power = booem
          })
        } else {
          item.power = booem
        }
      }
    },
    FromCancel () {
      this.$emit('cancel')
    },
    /* 确认-保存 */
    saveRole () {
      this.$refs.comInfo.validate((valid) => {
        if (!valid) return
        if (this.roleTest(this.relepers)) {
          const role = Base64.encode(JSON.stringify(this.relepers))
          if (this.editRole === null) { // 角色-新增
            const params = {
              rolename: this.roleFrom.rolename,
              roleposi: this.roleFrom.roleposi,
              relepers: role
            }
            console.log(params)
            roleAdd(params).then(res => {
              if (res.status === 1) {
                this.$message.success(res.message)
                this.$emit('formSuccess')
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            const params = {
              roleid: this.editRole.roleid,
              rolename: this.roleFrom.rolename,
              roleposi: this.roleFrom.roleposi,
              relepers: role
            }
            roleUpdate(params).then(res => {
              if (res.status === 1) {
                this.$message.success(res.message)
                this.$emit('formSuccess')
              } else {
                this.$message.error(res.message)
              }
            })
          }
        } else {
          this.$message.warning('请给角色配置权限！')
        }
      })
    },
    roleTest (arr) {
      return arr.some((item, index) => {
        return item.power
      })
    }
  }
}
</script>

<style scoped>
.role_cont{
  width: 100%;
  height: calc(100% - 30px);
  position: relative;
  padding-bottom: 20px;
  overflow-y: scroll;
  scrollbar-width: none;/* //火狐兼容 */
}
.role_cont::-webkit-scrollbar {
  width: 1px;
}

.btn_pos{
  position: fixed;
  bottom: 30px;
  right: 30px;
}
.level1{
  margin: 15px 0px;
}
.level2,.level3{
  padding-left: 25px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}
.levelT{
  padding-left: 25px;
  box-sizing: border-box;
}
.span1 >>> .el-checkbox__label{
  font-size: 15px;
  font-weight: 700;
}
.span1,.span2,.span3{
  margin-right: 15px;
}
.span1 >>> .el-checkbox__label,
.span2 >>> .el-checkbox__label,
.span3 >>> .el-checkbox__label{
  padding-left: 5px;
}
</style>
