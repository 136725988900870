<template>
<!-- 角色管理 -->
<div class="box100">
  <div class="whiteBg" v-if="!addFromShow">
    <template v-if="roleBtn[0].power">
      <div class="el-icon-plus settingAdd" @click="handleAdd"><span> 新增</span></div>
    </template>
    <div class="table_cont">
      <el-table
        :header-cell-style="{background:'#EBF2FE'}"
        :data="roleList"
        style="width: 100%">
        <el-table-column
          label="序号"
          type="index">
        </el-table-column>
        <el-table-column
          label="角色名称"
          prop="rolename">
        </el-table-column>
        <el-table-column
          label="角色描述"
          prop="roleposi">
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="scope">
            <template v-if="roleBtn[1].power">
              <el-button v-if="scope.row.roleid.length > 2" type="text" size="small" style="color:#F44C8E;" @click="handleDelete(scope.row)">删除</el-button>
            </template>
            <template v-if="roleBtn[2].power">
              <el-button v-if="scope.row.roleid.length > 2" type="text" size="small" style="color:#3C84EF;" @click="handleEdit(scope.row)">编辑</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="PageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage">
    </el-pagination>
  </div>

  <!-- 新增编辑 -->
  <div class="detailForm" v-if="addFromShow">
    <roleSetting @cancel="cancel" @formSuccess="formSuccess" :editRole="editRole"></roleSetting>
  </div>
</div>
</template>

<script>
import roleSetting from '@/components/role/roleSetting'
import { roleGet, roleDelete } from '@/api/role.js'
export default {
  name: 'index',
  props: ['roleBtn'],
  components: {
    roleSetting
  },
  data () {
    return {
      page: 1,
      size: 10,
      total: 0,
      roleList: [],
      addFromShow: false,
      editRole: null
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        // companyid: this.companyid,
        // companyname: this.companyname,
        page_index: this.page,
        page_size: this.size
      }
      roleGet(params).then(res => {
        if (res.status === 1) {
          this.roleList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.roleList = []
          this.total = 0
        }
      })
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    // 删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        roleDelete({ roleid: row.roleid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 新增-展示
    handleAdd () {
      this.editRole = null
      this.addFromShow = true
    },
    // 编辑-展示
    handleEdit (row) {
      this.editRole = { ...row }
      this.addFromShow = true
    },
    /* 角色返回 */
    cancel () {
      this.addFromShow = false
    },
    /* 角色-新增-编辑 提交成功 */
    formSuccess () {
      this.addFromShow = false
      this.init()
    }
  }
}
</script>

<style scoped>

</style>
