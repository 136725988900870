import { post } from '@/http/http'
// 角色查询（下拉）
export const roleDropGet = p => post('api/v1.0/roleinfo/roleinfo_get_down', p)

// 角色查询
export const roleGet = p => post('api/v1.0/roleinfo/roleinfo_get', p)

// 角色添加
export const roleAdd = p => post('api/v1.0/roleinfo/roleinfo_add', p)

// 角色编辑
export const roleUpdate = p => post('api/v1.0/roleinfo/roleinfo_update', p)

// 角色删除
export const roleDelete = p => post('api/v1.0/roleinfo/roleinfo_delete', p)